import { Svg, G, Ellipse, Path } from '@react-pdf/renderer';

export const BookIcon = () => (
  <Svg
    width="36"
    height="36"
    style={{ transform: 'scale(0.8)', marginLeft: -4 }}
  >
    <G>
      <Ellipse ry="18" rx="18" cy="18" cx="18" fill="#ffa25b" />
      <Ellipse ry="14" rx="14" cy="18" cx="18" fill="#ff8c3a" />
      <G>
        <Path
          fill="#ffffff"
          stroke="#ffffff"
          d="m25,7.75l-5,5l0,11l5,-4.5l0,-11.5m2,4l0,13.5c-1.1,-0.35 -2.3,-0.5 -3.5,-0.5c-1.7,0 -4.15,0.65 -5.5,1.5l0,-13.5c-1.45,-1.1 -3.55,-1.5 -5.5,-1.5c-1.95,0 -4.05,0.4 -5.5,1.5l0,14.65c0,0.25 0.25,0.5 0.5,0.5c0.1,0 0.15,-0.05 0.25,-0.05c1.35,-0.65 3.3,-1.1 4.75,-1.1c1.95,0 4.05,0.4 5.5,1.5c1.35,-0.85 3.8,-1.5 5.5,-1.5c1.65,0 3.35,0.3 4.75,1.05c0.1,0.05 0.15,0.05 0.25,0.05c0.25,0 0.5,-0.25 0.5,-0.5l0,-14.6c-0.6,-0.45 -1.25,-0.75 -2,-1m-11,13.41c-1.25,-0.32 -2.5,-0.41 -3.5,-0.41c-1.06,0 -2.32,0.19 -3.5,0.5l0,-11.37c0.91,-0.4 2.14,-0.63 3.5,-0.63c1.36,0 2.59,0.23 3.5,0.63l0,11.28z"
        />
      </G>
    </G>
  </Svg>
);
