import { Debug, Physics } from '@react-three/cannon';
import {
  Environment,
  Html,
  PointerLockControls,
  Sky,
  Stars,
} from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { Euler, Vector3 } from 'three';
import { Quality, SettingsContext } from '../../contexts/SettingsContext';
import { MuseumCollision } from './geometry/MuseumCollision';
import { MuseumMesh } from './geometry/MuseumMesh';
import { PhysicsBall } from './geometry/PhysicsBall';
import { PhysicsPlane } from './geometry/PhysicsPlane';
import { Player } from './Player';

export const Museum: FC = () => {
  const { quality, setQuality, toggleDebug, debug, mobile } =
    useContext(SettingsContext);

  const { camera, gl } = useThree();

  const [musicHtmlHidden, setMusicHtmlHidden] = useState(false);
  const [siteHtmlHidden, setSiteHtmlHidden] = useState(false);
  const [woeHtmlHidden, setWoeHtmlHidden] = useState(false);
  const [siteToggle, setSiteToggle] = useState(false);

  const controls = useRef<any>();

  useEffect(() => {
    const handleFocus = () => {
      controls.current && controls.current.lock();
    };

    const handleKeypress = (e: KeyboardEvent) => {
      if (e.key === 'm') toggleDebug();
      if (e.key === 'r') setSiteToggle((s) => !s);
      if (e.key === '1') setQuality(Quality.LOW);
      if (e.key === '2') setQuality(Quality.MEDIUM);
      if (e.key === '3') setQuality(Quality.HIGH);
      if (e.key === '4') setQuality(Quality.ULTRA);
    };
    document.addEventListener('click', handleFocus);
    document.addEventListener('keypress', handleKeypress);

    return () => {
      document.removeEventListener('click', handleFocus);
      document.removeEventListener('keypress', handleKeypress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pointLightProps = {
    intensity: 0.5,
    castShadow: true,
    'shadow-bias': 0.000001,
    'shadow-normalBias': 0.08,
    'shadow-mapSize-height': 512,
    'shadow-mapSize-width': 512,
    'shadow-camera-near': 0.1,
    'shadow-camera-far': 30,
  };

  const lightRadius = 17;
  const lightRadiusSqrt = 18 / Math.sqrt(2);
  const lightHeight = 4;

  return (
    <>
      <Environment preset="night" />
      {quality >= Quality.MEDIUM && (
        <>
          <Sky
            distance={200}
            sunPosition={[1, -0.03, 0.5]}
            inclination={0}
            azimuth={0.5}
          />
          <Stars radius={100} depth={2} count={5000} factor={4} fade />
        </>
      )}
      {!mobile && (
        <PointerLockControls ref={controls} args={[camera, gl.domElement]} />
      )}
      {quality >= Quality.MEDIUM && (
        <pointLight position={new Vector3(0, 3.5, 0)} {...pointLightProps} />
      )}
      {quality >= Quality.ULTRA && (
        <>
          <pointLight
            position={new Vector3(lightRadius, lightHeight, 0)}
            {...pointLightProps}
          />
          <pointLight
            position={new Vector3(-lightRadius, lightHeight, 0)}
            {...pointLightProps}
          />
          <pointLight
            position={new Vector3(0, lightHeight, lightRadius)}
            {...pointLightProps}
          />
          <pointLight
            position={new Vector3(0, lightHeight, -lightRadius)}
            {...pointLightProps}
          />
          <pointLight
            position={
              new Vector3(lightRadiusSqrt, lightHeight, lightRadiusSqrt)
            }
            {...pointLightProps}
          />
          <pointLight
            position={
              new Vector3(-lightRadiusSqrt, lightHeight, lightRadiusSqrt)
            }
            {...pointLightProps}
          />
          <pointLight
            position={
              new Vector3(lightRadiusSqrt, lightHeight, -lightRadiusSqrt)
            }
            {...pointLightProps}
          />
          <pointLight
            position={
              new Vector3(-lightRadiusSqrt, lightHeight, -lightRadiusSqrt)
            }
            {...pointLightProps}
          />
        </>
      )}
      <hemisphereLight intensity={quality > Quality.LOW ? 0.2 : 0.6} />
      {quality >= Quality.MEDIUM && (
        <>
          <Html
            position={new Vector3(-7, 2.4, 13)}
            rotation={new Euler(0, -2.4, 0)}
            transform
            scale={0.2}
            occlude
            onOcclude={(visible) => {
              setMusicHtmlHidden(visible);
              return null;
            }}
            style={{
              transition: 'all 0.5s',
              opacity: musicHtmlHidden ? 0 : 1,
              transform: `scale(${musicHtmlHidden ? 0.5 : 1})`,
            }}
          >
            <iframe
              width={1920 / 2}
              height={1080 / 2}
              src="https://www.youtube.com/embed/vpp8JrUhb78"
              title="YouTube"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          </Html>
          <Html
            position={new Vector3(8.5, 2.6, 3)}
            rotation={new Euler(0, 2.7, 0)}
            transform
            occlude
            onOcclude={(visible) => {
              setWoeHtmlHidden(visible);
              return null;
            }}
            style={{
              transition: 'all 0.5s',
              opacity: woeHtmlHidden ? 0 : 1,
              transform: `scale(${woeHtmlHidden ? 0.5 : 1})`,
            }}
            scale={0.2}
          >
            <iframe
              width={1920 / 2}
              height={1080 / 2}
              src="https://www.axolotlstudios.com"
              title="World of Engineering"
            />
          </Html>
          {siteToggle && (
            <Html
              position={new Vector3(21.5, 2.6, 4)}
              rotation={new Euler(0, 3.2, 0)}
              transform
              occlude
              onOcclude={(visible) => {
                setSiteHtmlHidden(visible);
                return null;
              }}
              style={{
                transition: 'all 0.5s',
                opacity: siteHtmlHidden ? 0 : 1,
                transform: `scale(${siteHtmlHidden ? 0.5 : 1})`,
              }}
              scale={0.2}
            >
              <iframe
                width={1920 / 2}
                height={1080 / 2}
                src="https://www.entingliu.com"
                title="Personal"
              />
            </Html>
          )}
        </>
      )}
      <MuseumMesh />
      <Physics stepSize={1 / 144} gravity={[0, -20, 0]}>
        <Debug color="black" scale={debug ? 1.0 : 0.00001}>
          <Player />
          <PhysicsPlane position={-0.02} />
          {quality > Quality.MEDIUM && <PhysicsBall position={[3, 2.0, 3]} />}
          <MuseumCollision />
        </Debug>
      </Physics>
    </>
  );
};
