import EmailIcon from '@mui/icons-material/Email';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Box, Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

export const Contact = () => {
  return (
    <ScreenContainer>
      <Text>You can reach me at the following links.</Text>
      <Text>For business inquiries, please send to my email.</Text>
      <Text>Join my discord to talk about my game or personal matters.</Text>
      <Horizontal>
        <EmailIcon color="secondary" />
        <StyledButton
          color="success"
          variant="contained"
          onClick={() => {
            window.open('mailto:generocket@engineering.ucla.edu');
          }}
        >
          Email
        </StyledButton>
      </Horizontal>
      <Horizontal>
        <InsertLinkIcon color="secondary" />
        <StyledButton
          color="success"
          variant="contained"
          onClick={() => {
            window.open('https://discord.gg/2Ysf7Z69Uh');
          }}
        >
          Discord
        </StyledButton>
      </Horizontal>
      <Horizontal>
        <OndemandVideoIcon color="secondary" />
        <StyledButton
          color="success"
          variant="contained"
          onClick={() => {
            window.open(
              'https://www.youtube.com/channel/UCHWhp4itUHxfhPwvJJ5TQaw'
            );
          }}
        >
          YouTube
        </StyledButton>
      </Horizontal>
      <Horizontal>
        <TwitterIcon color="secondary" />
        <StyledButton
          color="success"
          variant="contained"
          onClick={() => {
            window.open('https://twitter.com/axolotlStudios_');
          }}
        >
          Twitter
        </StyledButton>
      </Horizontal>
      <Horizontal>
        <LinkedInIcon color="secondary" />
        <StyledButton
          color="success"
          variant="contained"
          onClick={() => {
            window.open('https://www.linkedin.com/in/gene-liu-83612a153/');
          }}
        >
          LinkedIn
        </StyledButton>
      </Horizontal>
    </ScreenContainer>
  );
};

const Text = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  margin: 20,
  marginBottom: 10,
  color: 'white',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '1rem',
  color: 'white',
  marginLeft: 12,
}));

const Horizontal = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: 20,
}));

const ScreenContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
}));

export default Contact;
