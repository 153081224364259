import { Box, Button, Grid, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const Main = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const theme = useTheme();
  return (
    <ScreenContainer container columns={{ xs: 1, sm: 1, md: 2 }}>
      <Grid item xs={1} sm={1} md={1}>
        <GridItem
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
          onPointerEnter={() => {
            setIndex(0);
          }}
        >
          <BackgroundImage
            src={'/images/museum.png'}
            sx={{
              opacity: index === 0 ? 0.6 : 0.2,
              transform: index === 0 ? 'scale(1.2)' : 'scale(1)',
            }}
          />
          <StyledButton
            color="secondary"
            variant="contained"
            onClick={() => {
              navigate('/museum');
            }}
          >
            Visit Museum of Me
          </StyledButton>
          <Text>
            *Requires decent hardware to view (Firefox and Edge support are
            limited)
          </Text>
          <Text>You can alternatively view the</Text>
          <StyledButton
            color="secondary"
            variant="contained"
            onClick={() => {
              window.location.assign('https://youtu.be/yw2-3fAUkvk');
            }}
          >
            Video Tour
          </StyledButton>
        </GridItem>
      </Grid>
      <Grid item xs={1} sm={1} md={1}>
        <GridItem
          sx={{
            backgroundColor: theme.palette.secondary.main,
          }}
          onPointerEnter={() => {
            setIndex(1);
          }}
        >
          <BackgroundImage
            src={'/images/museum.png'}
            sx={{
              opacity: index === 1 ? 0.6 : 0.2,
              transform: index === 1 ? 'scale(1.2)' : 'scale(1)',
            }}
          />
          <StyledButton
            variant="contained"
            onClick={() => {
              navigate('/resume');
            }}
          >
            View My Résumé
          </StyledButton>
        </GridItem>
      </Grid>
      <Grid item xs={1} sm={1} md={1}>
        <GridItem
          sx={{ backgroundColor: theme.palette.success.main }}
          onPointerEnter={() => {
            setIndex(2);
          }}
        >
          <BackgroundImage
            src={'/images/woe.png'}
            sx={{
              opacity: index === 2 ? 0.6 : 0.2,
              transform: index === 2 ? 'scale(1.2)' : 'scale(1)',
            }}
          />
          <StyledButton
            color="info"
            variant="contained"
            onClick={() => {
              window.location.assign('https://axolotlstudios.com');
            }}
          >
            World of Engineering Site
          </StyledButton>
        </GridItem>
      </Grid>
      <Grid item xs={1} sm={1} md={1}>
        <GridItem
          sx={{ backgroundColor: theme.palette.icon.main }}
          onPointerEnter={() => {
            setIndex(3);
          }}
        >
          <BackgroundImage
            src={'/images/me.png'}
            sx={{
              opacity: index === 3 ? 0.6 : 0.2,
              transform: index === 3 ? 'scale(1.2)' : 'scale(1)',
            }}
          />
          <StyledButton
            color="success"
            variant="contained"
            onClick={() => {
              navigate('/contact');
            }}
          >
            Contact
          </StyledButton>
        </GridItem>
      </Grid>
    </ScreenContainer>
  );
};

const Text = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: 'white',
  marginTop: 6,
  zIndex: 10,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '1rem',
  color: 'white',
}));

const ScreenContainer = styled(Grid)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
}));

const BackgroundImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  objectFit: 'cover',
  top: 0,
  left: 0,
  transition: 'all 0.5s',
}));

const GridItem = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
}));

export default Main;
