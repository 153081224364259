import { StyledEngineProvider } from '@mui/material';
import { FC } from 'react';
import ControlsContextProvider from './contexts/ControlsContext';
import SettingsContextProvider from './contexts/SettingsContext';
import { ThemeContextProvider } from './contexts/ThemeContext';
import { Router } from './navigation/Router';
import { Analytics } from '@vercel/analytics/react';

const App: FC = () => {
  return (
    <SettingsContextProvider>
      <ControlsContextProvider>
        <StyledEngineProvider injectFirst>
          <ThemeContextProvider>
            <Analytics />
            <Router />
          </ThemeContextProvider>
        </StyledEngineProvider>
      </ControlsContextProvider>
    </SettingsContextProvider>
  );
};

export default App;
