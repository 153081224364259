import { Svg, G, Ellipse, Path } from '@react-pdf/renderer';

export const ComputerIcon = () => (
  <Svg
    width="36"
    height="36"
    style={{ transform: 'scale(0.8)', marginLeft: -4 }}
  >
    <G>
      <Ellipse ry="18" rx="18" cy="18" cx="18" fill="#ffa25b" />
      <Ellipse ry="14" rx="14" cy="18" cx="18" fill="#ff8c3a" />
      <G>
        <Path
          fill="#ffffff"
          stroke="#ffffff"
          d="m10,12l16,0l0,10l-16,0m16,2a2,2 0 0 0 2,-2l0,-10c0,-1.11 -0.9,-2 -2,-2l-16,0c-1.11,0 -2,0.89 -2,2l0,10a2,2 0 0 0 2,2l-4,0l0,2l24,0l0,-2l-4,0z"
        />
      </G>
    </G>
  </Svg>
);
