import { Svg, G, Ellipse, Path } from '@react-pdf/renderer';

export const WorkIcon = () => (
  <Svg width="36" height="36" style={{ transform: 'scale(0.8)' }}>
    <G>
      <Ellipse ry="18" rx="18" cy="18" cx="18" fill="#ffa25b" />
      <Ellipse ry="14" rx="14" cy="18" cx="18" fill="#ff8c3a" />
      <G>
        <Path
          fill="#ffffff"
          d="m16,8.5l4,0a2,2 0 0 1 2,2l0,2l4,0a2,2 0 0 1 2,2l0,11a2,2 0 0 1 -2,2l-16,0c-1.11,0 -2,-0.9 -2,-2l0,-11c0,-1.11 0.89,-2 2,-2l4,0l0,-2c0,-1.11 0.89,-2 2,-2m4,4l0,-2l-4,0l0,2l4,0z"
        />
      </G>
    </G>
  </Svg>
);
