import {
  Document,
  Font,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import React, { FC } from 'react';
import { Skills } from './Skills';
import { EmailIcon } from './svgs/EmailIcon';
import { GithubIcon } from './svgs/GithubIcon';
import { PersonalIcon } from './svgs/PersonalIcon';
import { PhoneIcon } from './svgs/PhoneIcon';
import { SchoolIcon } from './svgs/SchoolIcon';
import { SummaryIcon } from './svgs/SummaryIcon';
import { WebIcon } from './svgs/WebIcon';
import { WorkIcon } from './svgs/WorkIcon';
import { YoutubeIcon } from './svgs/YoutubeIcon';
import {
  TEXT_AXO,
  TEXT_GAME,
  TEXT_ISBX,
  TEXT_NVIDIA,
  TEXT_PERSONAL,
  TEXT_SCHOOL,
  TEXT_SUMMARY,
} from './Texts';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: '/fonts/Roboto-Regular.ttf',
      fontWeight: 400,
    },
    {
      src: '/fonts/Roboto-Bold.ttf',
      fontWeight: 700,
    },
  ],
});

export const ResumePdf: FC = () => {
  return (
    <Document title="Enting (Gene) Liu's Resume" author="Enting Liu">
      <Page size="LETTER" wrap={true} style={styles.page}>
        <Text style={styles.headerText}>{'Enting (Gene) Liu'}</Text>
        <View style={styles.horizontalTop}>
          <View style={styles.horizontal}>
            <WebIcon />
            <Link src={'https://www.entingliu.com'} style={styles.linkText}>
              {'www.entingliu.com'}
            </Link>
          </View>
          <View style={styles.horizontal}>
            <GithubIcon />
            <Link
              src={'https://github.com/mudkip-hacker'}
              style={styles.linkText}
            >
              {'github'}
            </Link>
          </View>
          <View style={styles.horizontal}>
            <YoutubeIcon />
            <Link
              src={'https://www.youtube.com/channel/UCHWhp4itUHxfhPwvJJ5TQaw'}
              style={styles.linkText}
            >
              {'YouTube'}
            </Link>
          </View>
          <View style={styles.horizontal}>
            <EmailIcon />
            <Link
              src={'mailto:generocket@engineering.ucla.edu'}
              style={styles.linkText}
            >
              {'generocket@engineering.ucla.edu'}
            </Link>
          </View>
          <View style={styles.horizontal}>
            <PhoneIcon />
            <Link src={'tel:+19492936084'} style={styles.linkText}>
              {'+1 (949) 293-6084'}
            </Link>
          </View>
        </View>

        <>
          <View style={styles.horizontalSubtitle}>
            <SummaryIcon />
            <Text style={styles.subtitle}>{'Professional Summary'}</Text>
          </View>
          <Text style={styles.smallText}>{TEXT_SUMMARY}</Text>
        </>

        <View style={styles.bodyContainer}>
          <View style={styles.leftContainer}>
            <>
              <View style={styles.horizontalSubtitle}>
                <WorkIcon />
                <Text style={styles.subtitle}>{'Employment History'}</Text>
              </View>
              <Text style={styles.smallTitle}>
                {'Machine Learning Engineer. Nvidia. Santa Clara, CA'}
                &nbsp;&nbsp;
                <Link
                  src={'https://www.nvidia.com/en-us/'}
                  style={styles.linkText}
                >
                  {'www.nvidia.com'}
                </Link>
              </Text>
              <Text style={styles.dateText}>{'Jun. 2022 - Present'}</Text>
              {TEXT_NVIDIA.map((text, index) => (
                <Text key={index} style={styles.smallText}>
                  {text}
                </Text>
              ))}
              <Text style={styles.smallTitle}>
                {'Full Stack Engineer. ISBX. Los Angeles, CA'}&nbsp;&nbsp;
                <Link src={'https://www.isbx.com'} style={styles.linkText}>
                  {'www.isbx.com'}
                </Link>
              </Text>
              <Text style={styles.dateText}>
                {
                  'Jun. 2018 - Jun. 2020 (intern/part-time). Jun. 2020 - Jun. 2022 (full-time)'
                }
              </Text>
              {TEXT_ISBX.map((text, index) => (
                <Text key={index} style={styles.smallText}>
                  {text}
                </Text>
              ))}
            </>
          </View>
          <View style={styles.rightContainer}>
            <Skills />
          </View>
        </View>
        <View style={styles.bottomContainer}>
          <>
            <View style={styles.horizontalSubtitle}>
              <PersonalIcon />
              <Text style={styles.subtitle}>Personal Projects</Text>
            </View>
          </>
          <>
            <Text style={styles.smallTitle}>
              {
                'World of Engineering. procedurally generated sandbox voxel game'
              }
            </Text>
            <Text style={styles.dateText}>
              {'May. 2020 - Present'}&nbsp;&nbsp;
              <Link
                src={'https://www.axolotlstudios.com'}
                style={styles.linkText}
              >
                {'www.axolotlstudios.com'}
              </Link>
            </Text>

            {TEXT_GAME.slice(0, 1).map((text, index) => (
              <Text key={index} style={styles.smallText}>
                {text}
              </Text>
            ))}
          </>
          <>
            {TEXT_GAME.slice(1).map((text, index) => (
              <Text key={index} style={styles.smallText}>
                {text}
              </Text>
            ))}
            <>
              <Text style={styles.smallTitle}>
                {'Axolotl Studios Official Site'}&nbsp;&nbsp;
                <Link
                  src={'https://www.axolotlstudios.com'}
                  style={styles.linkText}
                >
                  {'www.axolotlstudios.com'}
                </Link>
              </Text>
              <Text style={styles.dateText}>{'Jun. 2021 - Present'}</Text>
              {TEXT_AXO.map((text, index) => (
                <Text key={index} style={styles.smallText}>
                  {text}
                </Text>
              ))}
            </>
            <>
              <Text style={styles.smallTitle}>
                {'Personal Portfolio Website'}&nbsp;&nbsp;
                <Link src={'https://www.entingliu.com'} style={styles.linkText}>
                  {'www.entingliu.com'}
                </Link>
              </Text>
              <Text style={styles.dateText}>{'Mar. 2022 - Present'}</Text>
              {TEXT_PERSONAL.map((text, index) => (
                <Text key={index} style={styles.smallText}>
                  {text}
                </Text>
              ))}
            </>
            <>
              <View style={styles.horizontalSubtitle}>
                <SchoolIcon />
                <Text style={styles.subtitle}>{'Education'}</Text>
              </View>
              <Text style={styles.smallTitle}>
                {
                  'B.S. Aerospace Engineering. University of California, Los Angeles'
                }
              </Text>
              <Text style={styles.dateText}>
                {'Oct. 2017 - Jun. 2020, 3 year accelerated plan'}
              </Text>
              {TEXT_SCHOOL.map((text, index) => (
                <Text key={index} style={styles.smallText}>
                  {text}
                </Text>
              ))}
            </>
          </>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 14,
    fontFamily: 'Roboto',
  },
  header: {
    width: '100%',
  },
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: -6,
  },
  horizontalTop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 2,
    marginBottom: -12,
  },
  horizontalSubtitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 14,
    marginBottom: -4,
  },
  headerText: {
    marginBottom: 6,
    marginLeft: 4,
    fontSize: '26pt',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '14pt',
    marginLeft: 6,
    fontWeight: 'bold',
  },
  smallTitle: {
    marginTop: 12,
    marginLeft: 6,
    fontSize: '13pt',
    fontWeight: 'bold',
  },
  dateText: {
    fontSize: '12pt',
    marginLeft: 6,
    color: '#ff8c3a',
  },
  smallText: {
    fontSize: '12pt',
    marginLeft: 6,
    marginTop: 6,
  },
  linkText: {
    fontSize: '10pt',
    marginLeft: 4,
    marginRight: 6,
    fontWeight: 'normal',
    color: '#2581dd',
    textDecoration: 'none',
  },
  skillText: {
    fontSize: '10pt',
    width: 90,
  },
  bodyContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  leftContainer: {
    width: '68%',
    marginRight: 10,
  },
  rightContainer: {
    width: '32%',
    marginTop: 12,
    paddingLeft: 10,
    paddingRight: 8,
    paddingBottom: 10,
    marginRight: 6,
    backgroundColor: '#4397ec29',
    borderRadius: 4,
  },
  bottomContainer: {},
  note: {
    position: 'absolute',
    right: 25,
    top: 25,
  },
});
