import { Svg, G, Path } from '@react-pdf/renderer';

export const EmailIcon = () => (
  <Svg
    width="36"
    height="36"
    style={{ transform: 'scale(0.8)', marginRight: -8 }}
  >
    <G>
      <Path
        stroke-width="0"
        fill="#ff8c3a"
        d="m26,14l-8,5l-8,-5l0,-2l8,5l8,-5m0,-2l-16,0c-1.11,0 -2,0.89 -2,2l0,12a2,2 0 0 0 2,2l16,0a2,2 0 0 0 2,-2l0,-12c0,-1.11 -0.9,-2 -2,-2z"
      />
    </G>
  </Svg>
);
