import { useSphere } from '@react-three/cannon';
import { useFrame, useThree } from '@react-three/fiber';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { Euler, Vector3 } from 'three';
import { ControlsContext } from '../../contexts/ControlsContext';
import { Quality, SettingsContext } from '../../contexts/SettingsContext';
import { useKeyboardInput } from './hooks/useKeyboardInput';
import { useVariable } from './hooks/useVariable';
import { Bullet } from './geometry/Bullet';

const baseSpeed = 7;
const bulletSpeed = 40;
const bulletCoolDown = 300;
const jumpSpeed = 3;

export const Player: FC = () => {
  const { quality, mobile } = useContext(SettingsContext);
  const { getMoved, getShot, getDragged, zoom } = useContext(ControlsContext);
  const { camera } = useThree();

  useEffect(() => {
    camera.setRotationFromEuler(new Euler(0, 3.7, 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [sphereRef, api] = useSphere(() => ({
    mass: 100,
    fixedRotation: true,
    position: [-0.9, 0.9, -1.6],
    args: [0.66],
    material: {
      friction: 0,
    },
  }));
  const [bullets, setBullets] = useState<any[]>([]);
  const pressed = useKeyboardInput([
    'w',
    'a',
    's',
    'd',
    ' ',
    'control',
    'shift',
  ]);

  const input = useVariable(pressed);

  const state = useRef({
    timeToShoot: 0,
    timeTojump: 0,
    vel: [0, 0, 0],
    jumping: false,
  });

  useEffect(() => {
    api.velocity.subscribe((v) => (state.current.vel = v));
  }, [api]);

  useFrame(() => {
    const { w, s, a, d, control, shift } = input.current;
    const space = input.current[' '];

    let velocity = new Vector3(0, 0, 0);
    let cameraDirection = new Vector3();
    camera.getWorldDirection(cameraDirection);

    let forward = new Vector3();
    forward.setFromMatrixColumn(camera.matrix, 0);
    forward.crossVectors(camera.up, forward);

    let right = new Vector3();
    right.setFromMatrixColumn(camera.matrix, 0);

    if (mobile) {
      const dragged = getDragged();
      camera.rotateOnWorldAxis(new Vector3(0, 1, 0), -dragged.x * 0.0004);
      camera.rotateX(-dragged.y * 0.0004);
    }
    if (zoom) camera.zoom = 5;
    else camera.zoom = 1;
    camera.updateProjectionMatrix();

    let [horizontal, vertical] = [0, 0];

    if (w) {
      vertical += 1;
    }
    if (s) {
      vertical -= 1;
    }
    if (d) {
      horizontal += 1;
    }
    if (a) {
      horizontal -= 1;
    }
    const moved = getMoved();
    horizontal += moved.x * 0.1;
    vertical += moved.y * 0.1;

    let speed = baseSpeed;
    if (shift) {
      speed *= 2;
    }
    if (control) {
      speed /= 2;
    }

    if (horizontal !== 0 && vertical !== 0) {
      velocity
        .add(forward.clone().multiplyScalar(speed * vertical))
        .add(right.clone().multiplyScalar(speed * horizontal));
      velocity.clampLength(-speed, speed);
    } else if (horizontal !== 0) {
      velocity.add(right.clone().multiplyScalar(speed * horizontal));
    } else if (vertical !== 0) {
      velocity.add(forward.clone().multiplyScalar(speed * vertical));
    }

    api.velocity.set(velocity.x, state.current.vel[1], velocity.z);
    let pos: Vector3 = new Vector3();
    sphereRef.current?.getWorldPosition(pos);
    camera.position.set(pos.x, Math.min(pos.y + 1, 4.0), pos.z);

    if (space) {
      api.velocity.set(state.current.vel[0], jumpSpeed, state.current.vel[2]);
    }

    const bulletDirection = cameraDirection.clone().multiplyScalar(bulletSpeed);
    const bulletPosition = camera.position.clone().add(cameraDirection.clone());

    let maxBullets = 1;
    switch (quality) {
      case Quality.LOW:
        maxBullets = 0;
        break;
      case Quality.MEDIUM:
        maxBullets = 0;
        break;
      case Quality.HIGH:
        maxBullets = 1;
        break;
      case Quality.ULTRA:
        maxBullets = 5;
        break;
    }
    if (getShot() && quality >= Quality.HIGH) {
      const now = Date.now();
      if (now >= state.current.timeToShoot) {
        state.current.timeToShoot = now + bulletCoolDown;
        setBullets((bullets) => [
          ...bullets.slice(
            Math.max(0, bullets.length - maxBullets + 1),
            bullets.length
          ),
          {
            id: now,
            position: [bulletPosition.x, bulletPosition.y, bulletPosition.z],
            forward: [bulletDirection.x, bulletDirection.y, bulletDirection.z],
            velocity: [bulletDirection.x, bulletDirection.y, bulletDirection.z],
          },
        ]);
      }
    }
    if (quality < Quality.HIGH) {
      setBullets([]);
    }
  });
  return (
    <>
      {bullets.map((bullet) => {
        return (
          <Bullet
            key={bullet.id}
            velocity={bullet.forward}
            position={bullet.position}
          />
        );
      })}
      <mesh ref={sphereRef as any} castShadow>
        <sphereBufferGeometry args={[1, 32, 32]} />
        <meshPhysicalMaterial color={'hotpink'} />
      </mesh>
    </>
  );
};
