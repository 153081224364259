import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { BookIcon } from './svgs/BookIcon';
import { ComputerIcon } from './svgs/ComputerIcon';

export const Skills = () => {
  return (
    <>
      <>
        <View style={styles.horizontalSubtitle}>
          <ComputerIcon />
          <Text style={styles.subtitle}>{'Programming Skills'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.levelText}>{'Proficient'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>{'• C++20/stl'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>{'• CUDA/OpenGL/WebGL/GLSL'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>{'• React/TypeScript/HTML/CSS'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>{'• React Native/Expo'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>{'• NestJS/SQL/ORM'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.levelText}>{'Competent'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>{'• Python/TF/OpenCV/PyTorch'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>{'• Kotlin/Android'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>{'• MATLAB'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>{'• Arduino'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>{'• Java'}</Text>
        </View>
      </>
      <>
        <View style={styles.horizontalSubtitle}>
          <ComputerIcon />
          <Text style={styles.subtitle}>{'Software Skills'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.levelText}>{'Proficient'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>{'• Blender'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>{'• Unreal Engine'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.levelText}>{'Competent'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>{'• Solidworks'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>{'• GIMP'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>{'• ANSYS Fluent'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>{'• Logic Pro X'}</Text>
        </View>
      </>
      <>
        <View style={styles.horizontalSubtitle}>
          <BookIcon />
          <Text style={styles.subtitle}>{'Other skills'}</Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>
            {'• Fluent in English and Chinese'}
          </Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>
            {'• Piano virtuoso, competent at organ, saxophone, cello'}
          </Text>
        </View>
        <View style={styles.horizontal}>
          <Text style={styles.skillText}>
            {
              '• Enjoys optimization/STEM/design games like Factorio, Satisfactory, Space Engineers'
            }
          </Text>
        </View>
      </>
    </>
  );
};

const styles = StyleSheet.create({
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
  },
  horizontalSubtitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 0,
  },
  skillText: {
    fontSize: '11pt',
  },
  levelText: {
    fontSize: '12pt',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '12pt',
    marginLeft: 6,
    fontWeight: 'bold',
  },
});
