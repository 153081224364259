import { Triplet, useSphere } from '@react-three/cannon';
import React, { FC } from 'react';

type BulletProps = {
  position: Triplet;
  type?: 'Static' | 'Dynamic' | 'Kinematic' | undefined;
  velocity: Triplet;
};

export const Bullet: FC<BulletProps> = (props) => {
  const [sphereRef] = useSphere(() => ({
    mass: 1,
    material: {
      friction: 1,
      restitution: 0.1,
    },
    args: [0.2],
    position: props.position,
    velocity: props.velocity,
    type: props.type || 'Dynamic',
  }));

  return (
    <mesh ref={sphereRef as any} castShadow>
      <sphereBufferGeometry args={[0.2, 32, 32]} />
      <meshPhysicalMaterial color="hotpink" />
    </mesh>
  );
};
