import { Svg, G, Path } from '@react-pdf/renderer';

export const PhoneIcon = () => (
  <Svg
    width="36"
    height="36"
    style={{ transform: 'scale(0.8)', marginRight: -8 }}
  >
    <G>
      <G>
        <Path
          fill="#ff8c3a"
          d="m12.6,16.8c1.4,2.8 3.8,5.1 6.6,6.6l2.2,-2.2c0.3,-0.3 0.7,-0.4 1,-0.2c1.1,0.4 2.3,0.6 3.6,0.6c0.6,0 1,0.4 1,1l0,3.4c0,0.6 -0.4,1 -1,1c-9.4,0 -17,-7.6 -17,-17c0,-0.6 0.5,-1 1,-1l3.5,0c0.6,0 1,0.4 1,1c0,1.2 0.2,2.4 0.6,3.6c0.1,0.3 0,0.7 -0.2,1l-2.3,2.2m7.4,-7.8c-0.6,0 -1,0.4 -1,1s0.4,1 1,1s1,-0.4 1,-1s-0.4,-1 -1,-1m3,0c-0.6,0 -1,0.4 -1,1s0.4,1 1,1s1,-0.4 1,-1s-0.4,-1 -1,-1m3,0c-0.6,0 -1,0.4 -1,1s0.4,1 1,1s1,-0.4 1,-1s-0.4,-1 -1,-1m-6,3c-0.6,0 -1,0.4 -1,1s0.4,1 1,1s1,-0.4 1,-1s-0.4,-1 -1,-1m3,0c-0.6,0 -1,0.4 -1,1s0.4,1 1,1s1,-0.4 1,-1s-0.4,-1 -1,-1m3,0c-0.6,0 -1,0.4 -1,1s0.4,1 1,1s1,-0.4 1,-1s-0.4,-1 -1,-1m-6,3c-0.6,0 -1,0.4 -1,1s0.4,1 1,1s1,-0.4 1,-1s-0.4,-1 -1,-1m3,0c-0.6,0 -1,0.4 -1,1s0.4,1 1,1s1,-0.4 1,-1s-0.4,-1 -1,-1m3,0c-0.6,0 -1,0.4 -1,1s0.4,1 1,1s1,-0.4 1,-1s-0.4,-1 -1,-1z"
        />
      </G>
    </G>
  </Svg>
);
