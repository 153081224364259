import { usePlane } from '@react-three/cannon';
import React, { FC } from 'react';

type PhysicsPlaneProps = {
  position: number;
};

export const PhysicsPlane: FC<PhysicsPlaneProps> = ({ position }) => {
  const [ref] = usePlane(() => ({
    rotation: [-Math.PI / 2, 0, 0],
    position: [0, position, 0],
    material: {
      friction: 0.5,
    },
  }));

  return (
    <mesh ref={ref as any} scale={[100, 100, 100]} visible={false}>
      <planeBufferGeometry />
    </mesh>
  );
};
