import { Svg, G, Ellipse, Path } from '@react-pdf/renderer';

export const PersonalIcon = () => (
  <Svg width="36" height="36" style={{ transform: 'scale(0.8)' }}>
    <G>
      <Ellipse ry="18" rx="18" cy="18" cx="18" fill="#ffa25b" />
      <Ellipse ry="14" rx="14" cy="18" cx="18" fill="#ff8c3a" />
      <Path
        stroke-width="0"
        fill="#ffffff"
        d="m26,12.5l-4,0l0,-2a2,2 0 0 0 -2,-2l-4,0c-1.11,0 -2,0.89 -2,2l0,2l-4,0c-1.11,0 -2,0.89 -2,2l0,11a2,2 0 0 0 2,2l16,0a2,2 0 0 0 2,-2l0,-11a2,2 0 0 0 -2,-2m-10,-2l4,0l0,2l-4,0l0,-2m2,5a2.5,2.5 0 0 1 2.5,2.5a2.5,2.5 0 0 1 -2.5,2.5a2.5,2.5 0 0 1 -2.5,-2.5a2.5,2.5 0 0 1 2.5,-2.5m5,10l-10,0l0,-1.25c0,-1.38 2.24,-2.5 5,-2.5c2.76,0 5,1.12 5,2.5l0,1.25z"
      />
    </G>
  </Svg>
);
