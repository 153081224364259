import { Svg, G, Ellipse, Path } from '@react-pdf/renderer';

export const SummaryIcon = () => (
  <Svg width="36" height="36" style={{ transform: 'scale(0.8)' }}>
    <G>
      <Ellipse ry="18" rx="18" cy="18" cx="18" fill="#ffa25b" />
      <Ellipse ry="14" rx="14" cy="18" cx="18" fill="#ff8c3a" />
      <G>
        <Path
          fill="#ffffff"
          stroke="#ffffff"
          d="m12,21l2,0l0,2l-2,0m5,-4l7,0l0,2l-7,0m0,-6l7,0l0,2l-7,0m-5,-4l2,0l0,6l-2,0m-1,-10c-1.11,0 -2,0.9 -2,2l0,14a2,2 0 0 0 2,2l14,0a2,2 0 0 0 2,-2l0,-14a2,2 0 0 0 -2,-2l-14,0z"
        />
      </G>
    </G>
  </Svg>
);
