import { createContext, FC, useRef, useState } from 'react';

export const ControlsContext = createContext<{
  move: (x: number, y: number) => void;
  getMoved: () => { x: number; y: number };
  drag: (x: number, y: number) => void;
  getDragged: () => { x: number; y: number };
  shoot: () => void;
  getShot: () => boolean;
  zoom: boolean;
  toggleZoom: () => void;
}>(undefined!);

export const ControlsContextProvider: FC<{ children: any }> = (props) => {
  const moved = useRef({ x: 0, y: 0 });
  const dragged = useRef({ x: 0, y: 0 });
  const shot = useRef(false);
  const [zoom, setZoom] = useState(false);

  const move = (x: number, y: number) => {
    moved.current = { x, y };
  };

  const getMoved = () => {
    return moved.current;
  };

  const drag = (x: number, y: number) => {
    dragged.current = { x, y };
  };

  const getDragged = () => {
    const ret = dragged.current;
    dragged.current = { x: 0, y: 0 };
    return ret;
  };

  const shoot = () => {
    shot.current = true;
  };

  const getShot = () => {
    const ret = shot.current;
    shot.current = false;
    return ret;
  };

  const toggleZoom = () => {
    setZoom(!zoom);
  };

  return (
    <ControlsContext.Provider
      value={{
        move,
        getMoved,
        drag,
        getDragged,
        shoot,
        getShot,
        zoom,
        toggleZoom,
      }}
      {...props}
    />
  );
};

export default ControlsContextProvider;
