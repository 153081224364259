import { Svg, G, Path } from '@react-pdf/renderer';

export const WebIcon = () => (
  <Svg
    width="36"
    height="36"
    style={{ transform: 'scale(0.8)', marginLeft: -6, marginRight: -8 }}
  >
    <G>
      <G>
        <Path
          fill="#ff8c3a"
          d="m22.36,20c0.08,-0.66 0.14,-1.32 0.14,-2c0,-0.68 -0.06,-1.34 -0.14,-2l3.38,0c0.16,0.64 0.26,1.31 0.26,2c0,0.69 -0.1,1.36 -0.26,2m-5.15,5.56c0.6,-1.11 1.06,-2.31 1.38,-3.56l2.95,0c-0.96,1.65 -2.49,2.93 -4.33,3.56m-0.25,-5.56l-4.68,0c-0.1,-0.66 -0.16,-1.32 -0.16,-2c0,-0.68 0.06,-1.35 0.16,-2l4.68,0c0.09,0.65 0.16,1.32 0.16,2c0,0.68 -0.07,1.34 -0.16,2m-2.34,5.96c-0.83,-1.2 -1.5,-2.53 -1.91,-3.96l3.82,0c-0.41,1.43 -1.08,2.76 -1.91,3.96m-4,-11.96l-2.92,0c0.95,-1.66 2.49,-2.94 4.32,-3.56c-0.6,1.11 -1.05,2.31 -1.4,3.56m-2.92,8l2.92,0c0.35,1.25 0.8,2.45 1.4,3.56c-1.83,-0.63 -3.37,-1.91 -4.32,-3.56m-0.82,-2c-0.16,-0.64 -0.26,-1.31 -0.26,-2c0,-0.69 0.1,-1.36 0.26,-2l3.38,0c-0.08,0.66 -0.14,1.32 -0.14,2c0,0.68 0.06,1.34 0.14,2m4.36,-9.97c0.83,1.2 1.5,2.54 1.91,3.97l-3.82,0c0.41,-1.43 1.08,-2.77 1.91,-3.97m6.92,3.97l-2.95,0c-0.32,-1.25 -0.78,-2.45 -1.38,-3.56c1.84,0.63 3.37,1.9 4.33,3.56m-6.92,-6c-5.53,0 -10,4.5 -10,10a10,10 0 0 0 10,10a10,10 0 0 0 10,-10a10,10 0 0 0 -10,-10z"
        />
      </G>
    </G>
  </Svg>
);
