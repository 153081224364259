import { Triplet, useSphere } from '@react-three/cannon';
import React, { FC, useEffect, useState } from 'react';

type PhysicsCubeProps = {
  position: Triplet;
  type?: 'Static' | 'Dynamic' | 'Kinematic' | undefined;
};

export const PhysicsBall: FC<PhysicsCubeProps> = (props) => {
  const [color, setColor] = useState('white');
  const [cubeRef] = useSphere(() => ({
    mass: 20,
    args: [0.5],
    material: {
      friction: 1,
      restitution: 0.1,
    },
    position: props.position,
    type: props.type || 'Dynamic',
  }));

  useEffect(() => setColor('red'), []);

  return (
    <mesh ref={cubeRef as any} castShadow>
      <sphereBufferGeometry args={[0.5]} />
      <meshPhysicalMaterial color={color} />
    </mesh>
  );
};
