import { useTrimesh } from '@react-three/cannon';
import { useGLTF } from '@react-three/drei';
import { FC } from 'react';
import { Mesh } from 'three';

export const MuseumCollision: FC = () => {
  const { scene: collision } = useGLTF('/meshes/collision.glb');
  const [meshRef] = useTrimesh(() => ({
    mass: 1,
    args: [
      (collision.children[0] as Mesh).geometry.attributes.position.array,
      (collision.children[0] as Mesh).geometry.index?.array ?? [],
    ],
    material: {
      friction: 1,
      restitution: 0.1,
    },
    type: 'Static',
  }));

  return <primitive ref={meshRef} object={collision} visible={false} />;
};
