export const TEXT_SUMMARY =
  'Multidisciplinary engineer with a passion for developing high performance, graphics centered applications and games. Effective in both team and solo development environments. Fast learner in diverse topics. Assembles technical and artistic skills to create responsive and compelling software.';

export const TEXT_NVIDIA = [
  '• Development of feature, driver, and SDK components of DLSS related technologies: DLSS-SR, DLSS-RR, DLSS-G, and other unreleased products',
  '• Unreal Engine development, Streamline SDK development',
  '• Development of various internal tools for image quality analysis, graphics trace capture tools, testing and validation suites, and sample applications',
  '• SDK integration and debug support for various third party games and engines',
];

export const TEXT_ISBX = [
  '• Lead Research developer for numerous ML related projects: designed and implemented ML data pipeline using Python and YOLOR covering labeling, training, and inference; Created sensor data processing and 3D mesh generation with Python and OpenCV',
  '• Lead developer in render focused apps like: first-person/map 3D store virtual tour (XR supported) webapp with with Deck.gl and WebGL/GLSL; CAD export rendering webapp with Three/R3Fiber; Animation/shader focused interactive mobile companion app for smart device',
  '• Developer in several React/TS driven mobile responsive web projects, used major libraries like Material UI, Redux, Styled Components. ',
  '• Developer in mobile cross-platform projects with React Native, examples like order fulfillment CMS and autonomous fleet CMS. Developed Native Kotlin/Android with BLE integrations',
  '• Backend engineer for multiple servers utilizing NestJS, TypeORM, MySQL, PostgreSQL, and Swagger. Used secure and efficient database design principles.',
  '• Created various 3D art in Blender and developed web-friendly optimization workflows. Used created assets for frontend rendering applications, and created animations for marketing and training purposes',
];

export const TEXT_PERSONAL = [
  '• Created 3D portfolio site with React/Three. Site features 3D museum created within Blender. Museum contains LOD to adapt to hardware, physics for movement and projectiles, 3D web embeds, and mobile responsive controls. Site also contains resume constructed in code, along with viewer and exporter',
];

export const TEXT_AXO = [
  '• Made Official site for user management of World of Engineering. Implemented payment integration with Stripe. Game download made available with custom throttling protection. Created topic/message thread system for interacting with users. Implemented modding tools like interactive node based tech tree editor. Site features a video showcase collage of in-game captures',
  '• Created backend with NestJS/Swagger and database using MongoDB. Server handles requests from the website like user authz/authn, redeem code, site content, and payment. Server also handles requests from game client like version control and in-game user data',
];

export const TEXT_GAME = [
  '• Created C++20 engine from scratch, used OpenGL/Vulkan(WIP) for render API. Highly customizable multi-threaded world generation with FastNoise2. Morph body mesh generation.',
  '• Machine learning submodule: designed and trained CNN model for classification of in game images with Python/TensorFlow; Image capture and label tool built into game; Game uses CppFlow to import trained model and perform inference in real time with CUDA',
  '• Implemented custom realistic, first-principle driven physics. Utilized OpenAL for audio system. Used Boost ASIO for multiplayer client-server communication. Leveraged TinyHTTP for user data management with custom game server. World data serialization, compression, and loading. Multilingual unicode font glyph generation and rendering. Custom made GUI framework with sliders, buttons, dropdowns, and text editors',
  '• Implemented various render principles: cascaded shadow mapping, deferred shading, ambient/diffuse/specular lighting, texture atlasing, postprocessing and filters, SSR, SSAO, sorted translucency, animated textures',
];

export const TEXT_SCHOOL = [
  '• Programming experience including: C++ development with 2D game and algorithms; ASM code gen and performance analysis; MATLAB numerical analysis and dynamical system modeling; Bash and Linux development; Verilog FPGA signal processing circuit design. Arduino design with LEDs, speakers, photo-resistors, buttons; Excel programming for complex data analysis of rocket systems',
  '• Experience with CAE suites like Solidworks, Ansys, COMSOL in various design projects. 3D printing design and manufacture. ',
  '• Rocket Project club member for 2 years. Experiencee with machine shop manufacturing, hydrostatic plumbing tests, and launch day ground crew operations',
];
