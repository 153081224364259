import {
  Box,
  Button,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { Canvas } from '@react-three/fiber';
import React, { Suspense, useContext, useRef } from 'react';
import { Joystick } from 'react-joystick-component';
import { ControlsContext } from '../../contexts/ControlsContext';
import { Quality, SettingsContext } from '../../contexts/SettingsContext';
import { Museum } from './Museum';

export const MuseumOverlay = () => {
  const { mobile, quality, setQuality, toggleMobile, toggleDebug } =
    useContext(SettingsContext);
  const { move, shoot, drag, toggleZoom } = useContext(ControlsContext);
  const mouseDown = useRef<{ x: number; y: number } | null>(null);
  return (
    <ScreenContainer
      onClick={(e) => {}}
      onPointerDown={(e) => {
        if (mobile) mouseDown.current = { x: e.clientX, y: e.clientY };
      }}
      onPointerMove={(e) => {
        if (mouseDown.current && mobile) {
          e.preventDefault();
          drag(
            // movementX/Y is not guaranteed
            e.clientX - mouseDown.current?.x ?? 0,
            e.clientY - mouseDown.current?.y ?? 0
          );
        }
      }}
      onPointerUp={(e) => {
        if (mobile) {
          if (
            mouseDown.current !== null &&
            Math.abs(e.clientX - mouseDown.current.x) < 10 &&
            Math.abs(e.clientY - mouseDown.current.y) < 10
          ) {
            shoot();
          }
          mouseDown.current = null;
        } else {
          shoot();
        }
      }}
    >
      <UIContainer>
        <Header>
          <Text>
            {mobile
              ? 'Controls: movement(joystick), look(drag)' +
                (quality >= Quality.HIGH ? ', shoot(click)' : '')
              : 'Controls: movement(wasd), quality preset(low-1,medium-2,high-3,ultra-4), debug mode(m, reloads page), fullscreen(f11), unlock mouse(esc)' +
                (quality >= Quality.HIGH ? ', shoot/lock mouse(click)' : '')}
          </Text>
          {mobile && (
            <Horizontal>
              <Text>Quality: </Text>
              <StyledToggleButtonGroup
                value={quality}
                exclusive
                onChange={(e, value) => {
                  e.stopPropagation();
                  setQuality(value);
                }}
              >
                <ToggleButton value={Quality.LOW}>Low</ToggleButton>
                <ToggleButton value={Quality.MEDIUM}>Medium</ToggleButton>
                <ToggleButton value={Quality.HIGH}>High</ToggleButton>
                <ToggleButton value={Quality.ULTRA}>Ultra</ToggleButton>
              </StyledToggleButtonGroup>

              <StyledButton
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDebug();
                }}
              >
                Toggle Debug
              </StyledButton>

              <StyledButton
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleZoom();
                }}
              >
                Toggle Zoom
              </StyledButton>
            </Horizontal>
          )}
          <StyledButton
            onClick={(e) => {
              e.stopPropagation();
              toggleMobile();
            }}
            variant="contained"
          >
            {mobile ? 'Desktop Controls' : 'Mobile Controls'}
          </StyledButton>
        </Header>
        <Crosshair />
        {mobile && (
          <JoystickContainer>
            <Joystick
              size={100}
              move={(e: any) => {
                move(e.x ?? 0, e.y ?? 0);
                mouseDown.current = null;
              }}
              start={(e: any) => {
                mouseDown.current = null;
              }}
              stop={(e: any) => {
                move(0, 0);
                mouseDown.current = null;
              }}
            />
          </JoystickContainer>
        )}
      </UIContainer>
      <Suspense fallback={<Progress size={100} />}>
        <SettingsContext.Consumer>
          {(value) => (
            <ControlsContext.Consumer>
              {(value1) => (
                <Canvas shadows>
                  <SettingsContext.Provider value={value}>
                    <ControlsContext.Provider value={value1}>
                      <Museum />
                    </ControlsContext.Provider>
                  </SettingsContext.Provider>
                </Canvas>
              )}
            </ControlsContext.Consumer>
          )}
        </SettingsContext.Consumer>
      </Suspense>
    </ScreenContainer>
  );
};

const Crosshair = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: 8,
  width: 8,
  backgroundColor: 'white',
  borderRadius: '50%',
  border: 'solid 2px #2d6aa7',
  margin: 'auto',
  pointerEvents: 'none',
  userSelect: 'none',
}));

const JoystickContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 60,
  left: 60,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: 3,
  height: 40,
}));

const Horizontal = styled(Box)(({ theme }) => ({
  flexDirection: 'row',
  display: 'flex',
  alignItem: 'center',
  flexWrap: 'wrap',
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  margin: 3,
  height: 40,
}));

const Header = styled(Horizontal)(({ theme }) => ({
  position: 'absolute',
  top: '0%',
  left: '0%',
  width: '100%',
  backgroundColor: '#00000088',
  padding: 5,
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: 'white',
  marginTop: 12,
  marginLeft: 5,
  marginRight: 5,
}));

const ScreenContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  touchAction: 'none',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
}));

const Progress = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  top: '45%',
  left: '47.5%',
}));

const UIContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  zIndex: 99,
  position: 'absolute',
  top: 0,
  left: 0,
  justifyItems: 'center',
  alignItems: 'center',
}));

export default MuseumOverlay;
