import { useGLTF } from '@react-three/drei';
import { FC, useEffect } from 'react';

export const MuseumMesh: FC = () => {
  const gltf = useGLTF('/meshes/scene.glb');

  useEffect(() => {
    Object.values((gltf as any).nodes).forEach(
      (obj: any) =>
        obj.isMesh &&
        Object.assign(obj, { castShadow: true, receiveShadow: true })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <primitive object={gltf.scene} castShadow>
      <meshPhysicalMaterial />
    </primitive>
  );
};
