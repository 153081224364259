import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import React, { FC, ReactElement } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

export const Resume: FC<{ document: ReactElement }> = ({ document }) => {
  return (
    <>
      <MobileView>
        <ScreenContainer>
          <Text>
            Viewing on mobile platform requires you to download the pdf first
          </Text>
          <DownloadButton variant="contained" color="secondary">
            <PDFDownloadLink
              document={document}
              fileName="Enting (Gene) Liu Resume"
            >
              {({ blob, url, loading, error }) =>
                loading ? 'loading...' : 'Download'
              }
            </PDFDownloadLink>
          </DownloadButton>
        </ScreenContainer>
      </MobileView>
      <BrowserView>
        <ScreenContainer>
          <PDFViewer style={{ width: '100%', height: '100%', border: 'none' }}>
            {document}
          </PDFViewer>
        </ScreenContainer>
      </BrowserView>
    </>
  );
};

const Text = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: 'white',
  zIndex: 10,
  padding: 20,
}));

const DownloadButton = styled(Button)(({ theme }) => ({
  margin: 20,
  padding: 10,
}));

const ScreenContainer = styled(Box)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  backgroundColor: theme.palette.primary.main,
}));

export default Resume;
