import { Svg, G, Path } from '@react-pdf/renderer';

export const YoutubeIcon = () => (
  <Svg
    width="36"
    height="36"
    style={{ transform: 'scale(0.8)', marginRight: -6 }}
  >
    <G>
      <G>
        <Path
          fill="#ff8c3a"
          d="m16,21l5.19,-3l-5.19,-3l0,6m11.56,-7.83c0.13,0.47 0.22,1.1 0.28,1.9c0.07,0.8 0.1,1.49 0.1,2.09l0.06,0.84c0,2.19 -0.16,3.8 -0.44,4.83c-0.25,0.9 -0.83,1.48 -1.73,1.73c-0.47,0.13 -1.33,0.22 -2.65,0.28c-1.3,0.07 -2.49,0.1 -3.59,0.1l-1.59,0.06c-4.19,0 -6.8,-0.16 -7.83,-0.44c-0.9,-0.25 -1.48,-0.83 -1.73,-1.73c-0.13,-0.47 -0.22,-1.1 -0.28,-1.9c-0.07,-0.8 -0.1,-1.49 -0.1,-2.09l-0.06,-0.84c0,-2.19 0.16,-3.8 0.44,-4.83c0.25,-0.9 0.83,-1.48 1.73,-1.73c0.47,-0.13 1.33,-0.22 2.65,-0.28c1.3,-0.07 2.49,-0.1 3.59,-0.1l1.59,-0.06c4.19,0 6.8,0.16 7.83,0.44c0.9,0.25 1.48,0.83 1.73,1.73z"
        />
      </G>
    </G>
  </Svg>
);
