import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Contact from '../screens/contact/Contact';
import Main from '../screens/main/Main';
import MuseumOverlay from '../screens/museum/MuseumOverlay';
import { ResumePdf } from '../screens/resume/ResumePDF';
import Resume from '../screens/resume/Resume';

export const Router: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="museum" element={<MuseumOverlay />} />
        <Route path="resume" element={<Resume document={<ResumePdf />} />} />
        <Route path="contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
};
