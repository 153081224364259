import { CssBaseline, useMediaQuery } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system';
import { createContext, FC, useEffect, useMemo, useState } from 'react';

export enum ThemeType {
  light = 'light',
  dark = 'dark',
  minimal = 'minimal',
}

export const ThemeContext = createContext({
  setTheme: {} as React.Dispatch<React.SetStateAction<ThemeType>>,
});

declare module '@mui/material/styles' {
  interface Palette {
    icon: Palette['primary'];
  }
  interface PaletteOptions {
    icon: PaletteOptions['primary'];
  }
}

export const ThemeContextProvider: FC<{ children: any }> = (props) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState<ThemeType>(
    prefersDarkMode ? ThemeType.dark : ThemeType.light
  );

  useEffect(() => {
    setMode(prefersDarkMode ? ThemeType.dark : ThemeType.light);
  }, [prefersDarkMode]);

  const theme = useMemo(() => {
    switch (mode) {
      case ThemeType.light:
        return responsiveFontSizes(
          createTheme({
            palette: {
              icon: { main: '#196a99' },
              secondary: { main: '#f09125' },
              primary: { main: '#283e6e' },
              text: { primary: '#e6e6e6' },
              success: { main: '#0FA958' },
            },
          })
        );
      case ThemeType.dark:
        return responsiveFontSizes(
          createTheme({
            palette: {
              icon: { main: '#196a99' },
              secondary: { main: '#f09125' },
              primary: { main: '#283e6e' },
              text: { primary: '#e6e6e6' },
              success: { main: '#0FA958' },
            },
          })
        );
      case ThemeType.minimal:
        return responsiveFontSizes(
          createTheme({
            palette: {
              icon: { main: '#196a99' },
              secondary: { main: '#f09125' },
              primary: { main: '#283e6e' },
              text: { primary: '#e6e6e6' },
              success: { main: '#0FA958' },
            },
          })
        );
    }
  }, [mode]);

  return (
    <ThemeContext.Provider
      value={{
        setTheme: setMode,
      }}
    >
      <CssBaseline />
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
