import { createContext, FC, useState } from 'react';
import { useCookies } from 'react-cookie';
import { isMobile } from 'react-device-detect';

export enum Quality {
  LOW = 0,
  MEDIUM = 1,
  HIGH = 2,
  ULTRA = 3,
}

export const SettingsContext = createContext<{
  quality: Quality;
  setQuality: React.Dispatch<React.SetStateAction<Quality>>;
  debug: boolean;
  toggleDebug: () => void;
  mobile: boolean;
  toggleMobile: () => void;
}>(undefined!);

export const SettingsContextProvider: FC<{ children: any }> = (props) => {
  const [quality, setQuality] = useState(Quality.LOW);
  const [cookies, setCookie] = useCookies(['debug']);
  const [mobile, setMobile] = useState(isMobile);

  const toggleDebug = () => {
    setCookie('debug', cookies.debug !== 'true', { path: '/' });
    window.location.reload();
  };

  const toggleMobile = () => {
    setMobile(!mobile);
  };

  return (
    <SettingsContext.Provider
      value={{
        quality,
        setQuality,
        debug: cookies.debug === 'true',
        toggleDebug,
        mobile,
        toggleMobile,
      }}
      {...props}
    />
  );
};

export default SettingsContextProvider;
